import {
  tableState,
  tableMutations,
  tableActions,
  tableGetters
} from "@tt/vue-components";
import nameFilter from "@/store/modules/usersOverview/filters/nameFilter.js";
import usernameFilter from "@/store/modules/usersOverview/filters/usernameFilter.js";
import barcodeFilter from "@/store/modules/usersOverview/filters/barcodeFilter.js";
import hasBarcodeFilter from "@/store/modules/usersOverview/filters/hasBarcodeFilter.js";
import isBlockedFilter from "@/store/modules/usersOverview/filters/isBlockedFilter.js";
import hasLocationsFilter from "@/store/modules/usersOverview/filters/hasLocationsFilter.js";
import locationsFilter from "@/store/modules/usersOverview/filters/locationsFilter.js";
import entityService from "@/services/EntityService.js";

const usersOverview = {
  requestController: null,
  namespaced: true,
  modules: {
    nameFilter,
    usernameFilter,
    barcodeFilter,
    hasBarcodeFilter,
    isBlockedFilter,
    hasLocationsFilter,
    locationsFilter
  },
  state: {
    ...tableState,
    user: null
  },
  mutations: {
    ...tableMutations,
    USER_STATUS(state, status) {
      state.user.blocked = status;
    },
    SET_USER(state, user) {
      state.user = user;
    }
  },
  actions: {
    ...tableActions,
    fetchItems(context, refresh = false) {
      if (refresh || context.state.items.length === 0) {
        context.commit("SET_ERROR", false);
        context.commit("SET_LOADING", true);
        const params = {
          pagination: context.state.pagination,
          sorting: context.state.sorting,
          search: context.state.search
        };

        if (this.requestController) {
          this.requestController.abort();
        }
        this.requestController = new AbortController();

        entityService.users.list(params, { signal: this.requestController.signal })
          .then(json => {
            if (json) {
              context.commit("SET_ITEMS", json);
            }
          })
          .catch(err => {
            context.commit("SET_ERROR", true);
            console.log(err);
          })
          .finally(() => {
            context.commit("SET_LOADING", false);
          });
      }
    },
    selectUser(context, user) {
      context.commit("SET_USER", user);
    },
    async editUser({commit}, user) {

      // API platform does not understand complete embedded relations, this might be due to a config error on the API side.
      if (user.locations.length) {
        let curLocations = user.locations;
        if (curLocations.length && curLocations[0]["@id"]) {
          user.locations = [];
          curLocations.forEach(location => {
             if (location["@id"] !== null) {
               user.locations.push(location["@id"]);
             }
          });
        }
      }

      return new Promise((resolve, reject) => {
        entityService.users.patch(user['id'], user)
          .then((data) => {
            commit("EDIT_ITEM", data);
            commit("SET_USER", data);
            resolve();
          })
          .catch(err => {
            reject(err["hydra:description"]);
          });
      });
    },
    async changePassword({ commit }, newPasswordObject) {
      return new Promise((resolve, reject) => {
        entityService.users.changePassword(newPasswordObject.id, {
          newPassword: newPasswordObject.newPassword,
          newPasswordConfirmation: newPasswordObject.newPasswordConfirmation
        })
          .then((data) => {
            commit("SET_USER", data);
            resolve();
          })
          .catch(err => {
            reject(err["hydra:description"]);
          });
      });
    },
    async addUser({commit, dispatch}, user) {
      return new Promise((resolve, reject) => {
        entityService.users.create(user)
          .then((data) => {
            commit("ADD_ITEM", data);
            dispatch("fetchItems", true);
            resolve();
          })
          .catch(err => {
            reject(err["hydra:description"]);
          });
      });
    },
    async deleteUser({commit, dispatch}, user) {
      return new Promise((resolve, reject) => {
        entityService.users.delete(user.id)
          .then(() => {
            commit("SET_USER", null);
            dispatch("fetchItems", true);
            resolve();
          })
          .catch(err => {
            reject(err["hydra:description"]);
          });
      });
    },
  },
  getters: {
    ...tableGetters,
    userLocations: state => {
      let locs = [];
      if (state.user !== null && state.user.locations.length > 0) {
        locs = [...state.user.locations].sort((a, b) => a.name.localeCompare(b.name));
      }
      return locs;
    }
  }
};

export default usersOverview;
